import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";

const SelectCommunity = ({ id = "select-community", ...rest }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const zip = userPrefs.get("workingZipcode");

  const handleSelect = (input) => {
    if (!userPrefs.zipcodeIsUpdating) {
      let tax = userPrefs.get("selectSalesTaxElecRate")
      async function asyncCall() {
        await userPrefs.set({
          workingZipcode: input,
          salesTax: tax[input][0],
          electricityRate: tax[input][1],
        });
        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall();
    }
  };
  return (
    <Select
      id={id}
      value={zip}
      ariaLabel="Select Community"
      optionNames={[
        intl.formatMessage({
          id: "location.estesPark",
          defaultMessage: "Estes Park",
        }),
        intl.formatMessage({
          id: "location.fortCollins",
          defaultMessage: "Fort Collins",
        }),
        intl.formatMessage({
          id: "location.longmont",
          defaultMessage: "Longmont",
        }),
        intl.formatMessage({
          id: "location.loveland",
          defaultMessage: "Loveland",
        }),
      ]}
      optionValues={[80511, 80522, 80501, 80537]}
      handler={(e) => handleSelect(e.target.value)}
    />
  );
};


export default SelectCommunity;

SelectCommunity.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
