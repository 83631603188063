import React, {useState, useContext } from "react";
import PropTypes from "prop-types";
import ControlledInputZipcode from "../../../components/InputComponents/InputZipcode/ControlledInputZipcode";
import SlideHouseholdIncome from "../../../components/InputComponents/SlideHouseholdIncome/SlideHouseholdIncome";
import SelectHouseholdSize from "../InputComponents/SelectHouseholdSize/SelectHouseholdSize";
import ChooseTaxFilingStatus from "../InputComponents/ChooseTaxFilingStatus/ChooseTaxFilingStatus.js";
import ChooseCanTurnInClunker from "../InputComponents/ChooseCanTurnInClunker/ChooseCanTurnInClunker";

import ToolTip from "../../../components/shared/ToolTip/ToolTip";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { ValidateFiveDigitCode } from "../../../utils/Helpers/PostalCodeValidator";

import { FormattedMessage, useIntl } from "react-intl";
import getUserPref from "../../../context/UserPrefs/getUserPref";

const IncentiveScoreOptions = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const [workingZipcode, setWorkingZipcode] = useState(
    userPrefs.get("zipcode")
  );


  const handleFormSubmit = () => {
    if (ValidateFiveDigitCode(workingZipcode)) {
      userPrefs.syncWorkingZipcode();
      userPrefs.toggleIncentivePrefsModal();
    }
  };
  const RenderItem = ({ component, tooltip_id, title_id, message }) => (
    <div>
      <div className="incentive-label-select">
        <FormattedMessage
          id={title_id}
          defaultMessage={"Item Title"}
          description={"Item Title"}
        />
        <ToolTip message={message} id={tooltip_id} />
      </div>
      {component}
    </div>
  );
  
  return (
    <div className="input-well MatchScoreOptions">
      <p className="h2">
        <FormattedMessage
          id="evs.incentivesScoreOptions"
          defaultMessage="Refine Match Score"
          description="Refine Match Score"
        />
      </p>
      <div style={{ marginBottom: "20px" }}>
        <ControlledInputZipcode
          zipcode={getUserPref("zipcode")}
          handleSubmit={(e) => {
            handleFormSubmit();
          }}
          workingZipcode={workingZipcode}
          setZipcode={(e) => {
            setWorkingZipcode(e.target.value);
            if (ValidateFiveDigitCode(e.target.value)) {
              userPrefs.set({ workingZipcode: e.target.value });
            }
          }}
          id="input-zipcode-for-rate-comp-launch"
          isUpdating={userPrefs.zipcodeIsUpdating}
          isNotFound={userPrefs.zipcodeIsNotFound}
          hideValidateRequest
        />
      </div>
      <RenderItem
        component={<SlideHouseholdIncome />}
        tooltip_id="household_income_tooltip"
        title_id={"incentives.householdIncome"}
        message={intl.formatMessage({
          id: "incentives.householdIncomeTooltip",
          defaultMessage: "Combine income from all household members. Used to determine incentive eligibility.",
        })}
      />
      <RenderItem
        component={<SelectHouseholdSize />}
        tooltip_id="household_size_tooltip"
        title_id={"incentives.householdSize"}
        message={intl.formatMessage({
          id: "incentives.householdSizeTooltip",
          defaultMessage: "Include all members of your household (spouse, dependants, etc). Used to determine incentived eligibility.",
        })}
      />
      <RenderItem
        component={<ChooseTaxFilingStatus />}
        tooltip_id="filing_status_tooltip"
        title_id={"incentives.taxFilingStatus"}
        message={intl.formatMessage({
          id: "incentives.taxFilingStatusTooltip",
          defaultMessage: "Used to determine incentive eligibility.",
        })}
      />
      <RenderItem
        component={<ChooseCanTurnInClunker />}
        tooltip_id="trade_in_tooltip"
        title_id={"incentives.tradeIn"}
        message={intl.formatMessage({
          id: "incentives.retiringVehicleTooltip",
          defaultMessage: "Retiring an old gas vehicle may be required for certain incentives.",
        })}
      />
    </div>
  );
};

export default IncentiveScoreOptions;

IncentiveScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
