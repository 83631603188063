import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";

import EVCatalog from "./../../components/EVCatalog/EVCatalog";
import MatchScoreOptions from "./../../client_customizations/components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "./../../components/EvSortControls/EvSortControls";

// import EVFilterControls from "./../../components/EVFilterControls/EVFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

import {FormattedMessage, useIntl} from 'react-intl';

const EVs = ({ electricVehicles, ip, uuid, incentives, userLocation }) => {
  const intl = useIntl() 
  
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_VEHICLES_TITLE;
  });

  const renderOptions = (
    <>
      <MatchScoreOptions vehicles={electricVehicles} incentives={incentives} location={userLocation}/>
      <br />
    </>
  );


  return (
    <section className="ev-container-fluid" id="EVs">
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <h1 className="evHead">
            <FormattedMessage
              id="electricVehicles"
              defaultMessage="Electric Vehicles"
              description="Electric Vehicles"
            />
          </h1>
          <p className="lead">
            <FormattedMessage
              id="evs.welcomeSub"
              defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
              description="Description Electric Vehicles"
              values={{
                lineBreak: <br />,
              }}
            />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="pull-right">
            <EvSortControls />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.buttonMatchScoreAndFilters",
                    defaultMessage: "Match Score and Filters",
                  })
                : "Match Score and Filters"
            }
            titleText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.matchScoreAndFilters",
                    defaultMessage: "Refine Match Score and Filters",
                  })
                : "Refine Match Score and Filters"
            }
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <EVCatalog
            vehicles={electricVehicles}
            hasEvDetails
            hasIncentivesAndMatchScore
          />
        </div>
      </div>
    </section>
  );
};

export default EVs;

EVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
  incentives: PropTypes.array,
  location: PropTypes.object,
};
