import React, { useContext } from "react";
import PropTypes from "prop-types";
import IncentiveCard from "../../../components/IncentiveCard/IncentiveCard";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {sortIncentivesCatalog, grantorFilter} from "../../../functions/incentives/Sort/sortIncentivesCatalog";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
const IncentiveCatalog = ({ incentives, titleText }) => {
  
  const userPrefs = useContext(UserPrefsContext);
  if (!incentives) return <LoadingSpinner />;

  const sortedIncentives = sortIncentivesCatalog(
    incentives,
    userPrefs.get("incentiveSortType"),
    userPrefs.get("incentiveSortDirection")
  );
  const filteredIncentives = grantorFilter(sortedIncentives)



  const renderCards = filteredIncentives.map((incentive, index) => {
    return <IncentiveCard incentive={incentive} key={index} />;
  });
    
  return (
    <div className="row mt-3 mb-3">
      <div className="col-sm-12">
        {titleText && <p className="h3 text-left">{titleText}</p>}
        <div className="d-flex justify-content-start flex-wrap">
          {renderCards}
        </div>
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool,
};
