import React, {useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"
import MonthlyFuelComparison from "../CostsCharts/MonthlyFuelComparison/MonthlyfuelComparison";
import FuelRangeChart from "../CostsCharts/FuelRangeChart";
import CostToOwnComparison from "../CostsCharts/CostToOwnComparison";
import CostOfOwnershipOptions from "./../CostOfOwnershipOptions/CostOfOwnershipOptions";
import { FormatCarName } from "./../../utils/Helpers/Format";
import SelectCommunity from "../../client_customizations/components/InputComponents/SelectCommunity/SelectCommunity";
import RenderAssumption from "../RenderAssumption/RenderAssumption";
import {
  SALES_TAX,
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH
} from  "../../client_customizations/data/assumptions/ASSUMPTIONS"
import { FormattedMessage, useIntl } from 'react-intl';
import BreakevenChart from "../CostsCharts/BreakevenChart";
import { loadInsuranceData } from "../../functions/vehicle/CostOfOwnership/api/loadInsuranceData";
import { loadMaintenanceData } from "../../functions/vehicle/CostOfOwnership/api/loadMaintenanceData";

const CostOfOwnership = ({ cars, title, comparison, usedEv, location }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [insuranceData, setInsuranceData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState(null);

  useEffect(() => {
    if (cars && cars.length > 0) {
      loadInsuranceData(
        cars[0].handle,
        userPrefs,
        setInsuranceData,
        setIsLoading
      );
      loadMaintenanceData(cars, userPrefs, setMaintenanceData, setIsLoading);
    }
  }, [cars, userPrefs]);

  if (!cars || cars.length === 0) return null;

  const car = cars[0]
  const equivalentGasVehicle = cars[1];

  SALES_TAX.title = intl.formatMessage({ id: "assumption.salesTax", defaultMessage: "Sales Tax"})
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title = intl.formatMessage({ id: "assumption.gasolinePrice", defaultMessage: "Price of Gasoline"})
  DOWN_PAYMENT.title = intl.formatMessage({ id: "assumption.downPayment", defaultMessage: "Down Payment"})
  DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({ id: "assumption.downPaymentValue", defaultMessage: "10% of MSRP + Tax"})
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title = intl.formatMessage({ id: "assumption.electricityRate", defaultMessage: "Electricity Rate"})
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted = process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE ? `$${userPrefs.get("electricityRate")}/kWh` : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted

  let salesTaxElecInfo = userPrefs.get("selectSalesTaxElecRate");
  for (let zip in salesTaxElecInfo) {
    if (location && zip === location.postcode) {
      SALES_TAX.value = salesTaxElecInfo[zip][0]
      ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value = salesTaxElecInfo[zip][1]

      SALES_TAX.valueAsFormatted = Math.round(SALES_TAX.value * 10000) / 100 + "%";
      ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted =
        "$" + Math.round(ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value * 100000) / 100000 + "/kWh";

      SALES_TAX.link = salesTaxElecInfo[zip][2];
    }
  }
  let GASOLINE_RATE_IN_DOLLARS_PER_GALLON = {
    title: GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title,
    link: GASOLINE_PRICE_IN_DOLLARS_PER_GAL.link,
    value: userPrefs.get("gasolinePriceInCentsPerGal")
      ? userPrefs.get("gasolinePriceInCentsPerGal") / 100
      : GASOLINE_PRICE_IN_DOLLARS_PER_GAL.value,
    valueAsFormatted: userPrefs.get("gasolinePriceInCentsPerGal")
      ? "$" + userPrefs.get("gasolinePriceInCentsPerGal") / 100 + "/gal"
      : GASOLINE_PRICE_IN_DOLLARS_PER_GAL.valueAsFormatted,
  };
  return (
    <section
      className={comparison ? null : `ev-container-fluid`}
      id="CostOfOwnership"
    >
      <div className="CostOfOwnership">
        <div className="row">
          {comparison ? null : (
            <div className={`col-sm-12 text-center`}>
              <p className={usedEv ? `compare-title p-3` : `compare-title`}>
                {title || (
                  <FormattedMessage
                    id="ev.compareGasVehicle"
                    defaultMessage="Compare the {year} {car} to a similar gas vehicle, the {gasCar}"
                    description="Description of Colton Recharged"
                    values={{
                      year: car["model_year"],
                      car: FormatCarName(car),
                      gasCar: FormatCarName(equivalentGasVehicle),
                    }}
                  />
                )}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="row graph-title top-margin">
        <FormattedMessage
          id="graph.title.costAnalysis"
          defaultMessage="Cost analysis"
          description="Cost analysis"
        />
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
          <CostOfOwnershipOptions cars={cars} usedEv={usedEv} />
          <br />
          <div className="input-well d-none d-md-block shadowed">
            <p
              className="RenderItemTitleLeft"
              style={{ marginBottom: 10, fontWeight: 700 }}
            >
              <FormattedMessage
                id="graph.community"
                defaultMessage="Community"
                description="Community"
              />
            </p>
            <SelectCommunity location={location} />
            <p
              className="RenderItemTitleLeft"
              style={{ marginBottom: 10, fontWeight: 700 }}
            >
              <FormattedMessage
                id="graph.assumptions"
                defaultMessage="Assumptions"
                description="Assumptions"
              />
            </p>

            <RenderAssumption assumption={SALES_TAX} />
            <RenderAssumption
              assumption={GASOLINE_RATE_IN_DOLLARS_PER_GALLON}
            />
            {userPrefs.get("purchaseMethod") === "cash" ? null : (
              <RenderAssumption assumption={DOWN_PAYMENT} />
            )}
            <RenderAssumption
              assumption={ELECTRICITY_RATE_IN_DOLLARS_PER_KWH}
            />
            <p className="legal-sub-disclaimer">
              <FormattedMessage
                id="ev.electricityRateCalc"
                defaultMessage="Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}"
                description="Compare Vehicle Text"
                values={{
                  website: (
                    <a
                      href="https://www.ci.colton.ca.us/957/Electric-Utility-Rate"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textDecoration: 'underline'}}
                    >
                      here.
                    </a>
                  ),
                }}
              />
            </p>
          </div>
          <p className="selected-subText">
            <FormattedMessage
              id="ev.carDetails.compareText"
              defaultMessage="We selected the {gasVehicle} based on its proximity to the {vehicle} based on brand, size, and price."
              description="Compare Text"
              values={{
                gasVehicle: FormatCarName(equivalentGasVehicle),
                vehicle: FormatCarName(car),
              }}
            />
          </p>
        </div>
        <div
          id="costs-chart-areas"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />
            {new Date().toLocaleString()}.
          </span>
        </div>
        <div className="col-md-8 col-lg-9">
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED &&
          !isLoading &&
          insuranceData &&
          maintenanceData ? (
            <MonthlyFuelComparison cars={cars} comparison={comparison} />
          ) : null}
          <br />
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON &&
          !isLoading &&
          insuranceData &&
          maintenanceData ? (
            <CostToOwnComparison
              cars={cars}
              hasExtraPadding
              comparison={comparison}
              insuranceData={insuranceData}
              maintenanceData={maintenanceData}
            />
          ) : null}
          <br />
          {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGT_ENABLED ? (
            <FuelRangeChart car={car} />
          ) : null}
          <br />
          {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH &&
          !isLoading &&
          insuranceData &&
          maintenanceData &&
          !comparison ? (
            <BreakevenChart
              cars={cars}
              insuranceData={insuranceData}
              maintenanceData={maintenanceData}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
  car: PropTypes.object
};
