import React, { useContext } from "react";
import PropTypes from "prop-types";
import unique from "lodash/uniq";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";

const SelectVehicleTrimFilter = ({
  id = "select-vehicle-trim-filter",
  vehicles,
  makeOption,
  modelOption,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage
    ? intl.formatMessage({ id: "trim", defaultMessage: "Trim" })
    : "Trim";
  const vehicleTrimOptions = (vehicles, makeOption, modelOption) => {
    if (modelOption && makeOption) {
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.model === modelOption;
      });
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.make === makeOption;
      });
      vehicles.sort((a, b) => {
        return a.trim.localeCompare(b.trim);
      });

      
      return unique((vehicles || []).map(ev => ev.trim).filter(trim => trim.length > 0))
    }
  };

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleTrimFilter")}
      label={label}
      optionNames={[
        intl.formatMessage
          ? intl.formatMessage({
              id: "incentives.selectTrim",
              defaultMessage: "Select Trim",
            })
          : "Select Trim",
        ...(vehicleTrimOptions(vehicles, makeOption, modelOption) || []),
      ]}
      optionValues={[
        "",
        ...(vehicleTrimOptions(vehicles, makeOption, modelOption) || []),
      ]}
      disabled={
        modelOption && vehicleTrimOptions(vehicles, makeOption, modelOption).length > 0
          ? false
          : true
      }
      handler={(e) => {
        const sortedVehicles = vehicles.filter(
          (vehicle) => vehicle.trim === e.target.value
        );
        sortedVehicles.length > 0
          ? userPrefs.set({
              vehicleTrimFilter: e.target.value,
              vehicleHandleForIncentives: sortedVehicles[0].handle,
            })
          : userPrefs.set({
              vehicleTrimFilter: e.target.value,
            });
      }}
    />
  );
};

export default SelectVehicleTrimFilter;

SelectVehicleTrimFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
