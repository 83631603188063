import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import BROWSE_VEHICLES from "../../client_customizations/assets/images/Discover_EVs.png";
import COMPARE_VEHICLES from "../../client_customizations/assets/images/Compare_Vehicles.png";
import EV_FACTS from "../../client_customizations/assets/images/Learn_More.png";
import { Link } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

const LinkCard = ({ type }) => {
  let src, link, title, button_title;

  switch (type) {
    case "BROWSE_VEHICLES":
      src = BROWSE_VEHICLES;
      link = "/vehicles";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="BROWSE ELECTRIC {lineBreak} VEHICLES"
            description="HomePage Linkcard Browse Electric Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      button_title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.browseButton"
            defaultMessage="Discover EVs"
            description="HomePage Linkcard Discover Button"
          />
        </span>
      );
      break;
    case "COMPARE_VEHICLES":
      src = COMPARE_VEHICLES;
      link = "/compare-vehicles";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.compareVehicles"
            defaultMessage="DISCOVER {lineBreak} INCENTIVES"
            description="HomePage Linkcard Discover Incentives"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      
      button_title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.compareButton"
            defaultMessage="Compare vehicles"
            description="HomePage Linkcard Compare Button"
          />
        </span>
      );
      break;
   
    case "EV_FACTS":
      src = EV_FACTS;
      link = "/learn-more";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.learnMore"
            defaultMessage="HOME {lineBreak} CHARGERS"
            description="HomePage Linkcard Home Chargers"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
        
      );
      button_title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.learnButton"
            defaultMessage="Learn more"
            description="HomePage Linkcard Learn More Button"
          />
        </span>
      );
      break;
    default:
      return null;
  }

  return (
    <div
      style={
        src === EV_FACTS
          ? { marginLeft: "10px" }
          : src === BROWSE_VEHICLES
          ? { marginRight: "10px" }
          : {}
      }
      className="link-card"
    >
      <Link to={link} className="evc-card">
        <div>
          <p className="h6">{title}</p>
          <img src={src} alt="" />
          <button
            style={button_title === "Learn more" ? { marginTop: "-10px" } : {}}
            className="btn btn-homepage"
          >
            {button_title}
          </button>
        </div>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};
