import React, { useContext } from "react";
import PropTypes from "prop-types";
import LinkCard from "../../../../components/LinkCard/LinkCard";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

const HomepageBigPromise = ({ homePageBannerImage }) => {
  const userPrefs = useContext(UserPrefsContext);
  return (
    <section className="p-0">
      <div className="big-promise" style={{ position: "relative" }}>
        <div className="gradient-top-left" />
        <div
          className="img-container"
          style={{
            backgroundImage: !userPrefs.zipcodeIsUpdating
              ? "url(" + homePageBannerImage + ")"
              : null,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "575px",
            boxSizing: "border-box",
          }}
        />
        <div className="container big-promise-text">
          <div>
            <h1>
              <FormattedMessage
                id="homepage.guide"
                defaultMessage="Your guide to electric vehicles"
                description="Guide Title"
              />
            </h1>
            <Link to="/vehicles">
              <FormattedMessage
                id="homepage.button"
                defaultMessage="Find your EV"
                description="Guide Button"
              />
            </Link>
          </div>
        </div>
      </div>

      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="centered">
              <h1 style={{ textAlign: "center" }}>
                <FormattedMessage
                  id="homepage.quickButtons.title"
                  defaultMessage="Estimate and compare costs, savings, incentives  more"
                  description="Vehicle Buttons Title"
                />
              </h1>
            </div>
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              {/* <LinkCard type="DISCOVER_INCENTIVES" /> */}
              <LinkCard type="COMPARE_VEHICLES" />
              <LinkCard type="EV_FACTS" />
              {/* <LinkCard type="FIND_DEALERS" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
};
