import React from "react";
import PropTypes from "prop-types";

import sortIncentives from "../../functions/incentives/Sort/sortIncentives";
import IncentiveCatalog from "../../client_customizations/components/IncentiveCatalog/IncentiveCatalog";
import { FormatAsDollars, FormatCarName } from "./../../utils/Helpers/Format";
import { FormattedMessage } from 'react-intl';

  const excluded_grantors = [
    "Poudre Valley R  E  A, Inc",
    "Xcel Energy of Colorado",
  ];

const EVIncentives = ({ car, incentives, usedEv }) => {
  if (!incentives) return null;


  incentives = usedEv
    ? incentives.filter((incentive) => incentive.applicable_to_used_vehicles)
    : incentives;

    
    

  incentives = incentives.filter((incentive) =>
    !excluded_grantors.includes(incentive.grantor)
  );


  const totalSavings = sortIncentives(incentives).total("eligible-rebates");
  return (
    <section className="ev-container-fluid" id="Incentives">
      <div className="EVIncentives">
        <div className="row">
          <div className="col-12">
            <p className="incentives-title text-center">
              <FormattedMessage
                id="ev.incentives.title"
                defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                description="EV Incentives Title"
                values={{
                  savings: (
                    <strong style={{ color: "#527500" }}>
                      {FormatAsDollars(totalSavings)}
                    </strong>
                  ),
                  lineBreak: <br />,
                  car: FormatCarName(car),
                }}
              />
            </p>
            <IncentiveCatalog incentives={incentives} usedEv={usedEv} />
          </div>
        </div>
      </div>
    </section>
  );
};;

export default EVIncentives;

EVIncentives.propTypes = {
  car: PropTypes.object,
  incentives: PropTypes.array
};
