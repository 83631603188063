// Source: https://docs.google.com/document/d/1IxO36l_f6CJGLjfmNWTvziNJv7trLSTVOLaI9NoIkj0/edit

// Removed Capitalized Lease Incentives because the user does not own the vehicle after lease
// import capitalizedInLease from "../../../incentives/capitalization/capitalizedInLease";
import nonCapitalizedInLease from "../../../incentives/capitalization/nonCapitalizedInLease";
import capitalizedInPurchase from "../../../incentives/capitalization/capitalizedInPurchase";

import ResidualValue from "../../../financial/calcResidualValue";
import LoanPayment from "../../../financial/calcLoanPayment";

import {
  DOWN_PAYMENT
} from "../../../../client_customizations/data/assumptions/ASSUMPTIONS"
import verifyIncentiveType from "../../../incentives/capitalization/verifyIncentiveType";

const VehicleCost = {
  msrp: function(vehicle) {
    if (!vehicle) return 0;

    return vehicle.msrp && parseInt(vehicle.msrp, 10) > 0
      ? parseInt(vehicle.msrp, 10)
      : 0;
  },

  eligibleIncentives: function(incentives) {
    return verifyIncentiveType(incentives);
  }, 

  msrpPlusTax: function(vehicle, salesTax) {
    return this.msrp(vehicle) * (1 + (salesTax / 10000));
  },

  afterIncentives: function(vehicle) {
    const incentives = capitalizedInPurchase(this.eligibleIncentives(vehicle.incentives));

    return this.msrp(vehicle) - incentives;
  },

  downPayment: function(vehicle, salesTax) {
    return this.msrpPlusTax(vehicle, Number(salesTax)) * DOWN_PAYMENT.value;
  },

  resaleValue: function(vehicle, lifetimeMiles, purchaseMethod) {
    return this.msrp(vehicle) * ResidualValue(lifetimeMiles, vehicle, purchaseMethod);
  },

  incentives: function(vehicle, purchaseMethod) {
    const eligibleIncentives = this.eligibleIncentives(vehicle.incentives);

    return purchaseMethod === "lease"
      ? 0
      : capitalizedInPurchase(eligibleIncentives);
  },

  nonCapitalizedLeaseIncentives: function(vehicle) {
    const eligibleIncentives = this.eligibleIncentives(vehicle.incentives);

    return vehicle && eligibleIncentives
      ? nonCapitalizedInLease(eligibleIncentives)
      : 0;
  },

  loanAmountToBeFinanced: function(vehicle, salesTax) {
    return this.msrpPlusTax(vehicle, salesTax) - this.downPayment(vehicle, salesTax);
  },

  monthlyLoanPayment: function(
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  ) {
    return LoanPayment.monthly(
      interestRateAsBasisPoints,
      monthsOfOwnership,
      this.loanAmountToBeFinanced(vehicle, salesTax)
    );
  },

  monthlyLoanPaymentsTotal: function(
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  ) {
    return (
      monthsOfOwnership *
      this.monthlyLoanPayment(
        vehicle,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        salesTax
      )
    );
  },

  moneyFactor: function(interestRateAsBasisPoints) {
    return interestRateAsBasisPoints / 240000;
  },

  leaseCapitalizedCost: function(vehicle, salesTax) {
    return (
      this.msrp(vehicle) -
      this.incentives(vehicle, "lease") -
      this.downPayment(vehicle, salesTax)
    );
  },

  monthlyDepreciationCost: function(vehicle, lifetimeMiles, monthsOfOwnership, salesTax) {
    return (
      (this.leaseCapitalizedCost(vehicle, salesTax) -
        this.resaleValue(vehicle, lifetimeMiles, "lease")) /
      monthsOfOwnership
    );
  },

  monthlyFinancingCost: function(
    vehicle,
    lifetimeMiles,
    interestRateAsBasisPoints,
    salesTax
  ) {
    return (
      (this.leaseCapitalizedCost(vehicle, salesTax) +
        this.resaleValue(vehicle, lifetimeMiles, "lease")) *
      this.moneyFactor(interestRateAsBasisPoints)
    );
  },

  monthlyLeaseDepreciationAndFinancingCosts: function(
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  ) {
    const lifetimeMiles = (annualMiles * monthsOfOwnership) / 12;

    return (
      this.monthlyDepreciationCost(vehicle, lifetimeMiles, monthsOfOwnership, salesTax) +
      this.monthlyFinancingCost(
        vehicle,
        lifetimeMiles,
        interestRateAsBasisPoints,
        salesTax
      )
    );
  },

  monthlyLeasePayment: function(
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  ) {
    return (
      (1 + Number(salesTax)) *
      this.monthlyLeaseDepreciationAndFinancingCosts(
        vehicle,
        annualMiles,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        salesTax
      )
    );
  },

  firstLeasePayment: function(
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  ) {
    return (
      this.downPayment(vehicle, salesTax) +
      this.monthlyLeasePayment(
        vehicle,
        annualMiles,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        salesTax
      )
    );
  },

  total: function(
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    purchaseMethod,
    includeResaleValue,
    salesTax
  ) {
    if (!vehicle) return 0;

    const lifetimeMiles = (annualMiles * monthsOfOwnership) / 12;

    let cost = 0;

    const resaleValue = includeResaleValue
    ? this.resaleValue(vehicle, lifetimeMiles)
    : 0;

    switch (purchaseMethod) {
      case "loan":
        cost =
          this.downPayment(vehicle, salesTax) +
          this.monthlyLoanPaymentsTotal(
            vehicle,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            salesTax
          ) -
          this.incentives(vehicle, "loan") -
          resaleValue;
        break;
      case "lease":
        cost =
          this.firstLeasePayment(
            vehicle,
            annualMiles,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            salesTax
          ) +
          (monthsOfOwnership - 1) *
            this.monthlyLeasePayment(
              vehicle,
              annualMiles,
              monthsOfOwnership,
              interestRateAsBasisPoints,
              salesTax
            ) -
          this.nonCapitalizedLeaseIncentives(vehicle);
        break;
      default:
        // case 'cash'
        cost =
          this.msrpPlusTax(vehicle, salesTax) - 
          this.incentives(vehicle, "cash") -
          resaleValue;
    }
    return isFinite(cost) ? cost : 0;
  },

  perMile: function(
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    purchaseMethod,
    includeResaleValue,
    salesTax
  ) {
    if (!vehicle) return 0;

    const totalVehicleCost = this.total(
      vehicle,
      annualMiles,
      monthsOfOwnership,
      interestRateAsBasisPoints,
      purchaseMethod,
      includeResaleValue,
      salesTax
    );
    const cost = totalVehicleCost / (annualMiles / 12) / monthsOfOwnership;

    return isFinite(cost) ? cost : 0;
  }
};

export default VehicleCost;
