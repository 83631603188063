import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import EVCard from "../EVCard/EVCard";

const VehicleCarousel = ({ electricVehicles }) => {
  const [selectedEv, setSelectedEv] = useState(1);
  const [firstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );

  if (!electricVehicles || electricVehicles.length <= 3) return null;

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]]
  ];

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <div
        onClick={() => setSelectedEv(i)}
        onKeyPress={() => setSelectedEv(i)}
        className={`${"evc-card EVCard " +
          (selectedEv === i ? " selected" : "not-selected")}`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasEvDetails
          hasLinkToEv={selectedEv === i}
          hasLocallyAvailableHidden
        />
      </div>
    );
  });

  const linkstoEvs = filteredEVs.map((ev, i) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={i}
      >
        <EVCard ev={ev} hasLocallyAvailableHidden />
      </Link>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        {/* <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ margEinTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            &lsaquo;
          </button>
        </div> */}
        <div className="col-sm-12">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        {/* <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            &rsaquo;
          </button>
        </div> */}
      </div>

      <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div>
    </>
  );

  return (
    <section
      className="container-fluid pb-0 grey"
      id="HomepageVehiclesCarousel"
    >
      <div className="row centered">
        <h1 style={{ marginTop: "10px" }}>
          <FormattedMessage
            id="homepage.vehicleCarousel.title"
            defaultMessage="There are {vehicleCount} electric vehicles available. Discover yours."
            description="Vehicle Carousel Title"
            values={{
              vehicleCount: vehicleCount
            }}
          />
        </h1>
        <div className="col-md-9 aligned">
          <h4 style={{ marginTop: "10px" }}>
            <FormattedMessage
              id="homepage.vehicleCarousel.subTitle"
              defaultMessage="Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it {lineBreak} can be cheaper to own and maintain than a 100% gasoline-powered car."
              description="Vehicle Carousel subTitle"
              values={{
                lineBreak: <br></br>,
              }}
            />
          </h4>
        </div>
        {/* <div className="col-md-3">
          <Link to="/vehicles" style={{margin:"12px"}} className="btn btn-ae mt-10" role="button">
            <FormattedMessage 
                id="homepage.vehicleCarousel.findEVButton"
                defaultMessage="See all vehicles"
                description="Find EV Button"
              />
          </Link>
        </div> */}
      </div>
      <section className="container">
        <div className="row mt-3">
          <div className="col-sm-12">{renderVehicles}</div>
          <div
            className="col-sm-12 d-none d-md-block"
            style={{ marginTop: "1rem" }}
          >
            {/* {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGET_ENABLED
            ? renderVehicleCostChart
            : null}
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED
            ? renderMonthlyFuelCostChart
            : null}
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON
            ? renderCostToOwnComparison
            : null} */}
          </div>
        </div>
      </section>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
