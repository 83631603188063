import {
AVERAGE_MSRP,
INSURANCE_COST_SCALING_FACTOR,
AVERAGE_INSURANCE_COST_ANNUALLY
} from "../../../../client_customizations/data/assumptions/ASSUMPTIONS"

// import  { MUNICIPALITY_INFO } from "../../../../data/assumptions/MUNICIPALITY";

const InsuranceCost = {
       perYear: function(vehicle, insuranceData) {
              if (!vehicle) return 0;
       //        let insurance = process.env.REACT_APP_DYNAMIC_INSURANCE_COST && MUNICIPALITY_INFO[municipality] !== undefined ?
       //       MUNICIPALITY_INFO[municipality].insurance : AVERAGE_INSURANCE_COST_ANNUALLY.value;

              let averageInsuranceCostAnnually = AVERAGE_INSURANCE_COST_ANNUALLY.value;
              if(insuranceData) {
                     const total = insuranceData.reduce((sum, premium) => sum + premium.insurance_premium, 0);
                     const average = total / insuranceData.length;
                     const roundedAverage = average.toFixed(2);
                     averageInsuranceCostAnnually = roundedAverage;
              }

              return (
              averageInsuranceCostAnnually  *
              (1 +
              INSURANCE_COST_SCALING_FACTOR.value *
                     (vehicle.msrp / AVERAGE_MSRP.value - 1))
              );
       },

       perMile: function(vehicle, milesDrivenAnnually, insuranceData) {
              if (!vehicle) return 0;

              let cost = this.perYear(vehicle, insuranceData) / milesDrivenAnnually;

              return isFinite(cost) ? cost : 0;
       }
};

export default InsuranceCost;
