import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { TabContent, TabPane, Nav } from "reactstrap";

import calcEmissions from "./../../../functions/vehicle/Emissions/calcEmissions";
import isPHEV from "../../../functions/vehicle/isPHEV";
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FormatCarName,
  FormatAsInt,
  FormatAsThousands,
  FormatAsPercentRounded,
  FormatAsTime
} from "./../../../utils/Helpers/Format";
import {
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL,
  ELECTRICITY_EMISSIONS_IN_KG_PER_MWH,
  EMISSIONS_PER_TREE_IN_KG_OF_CO2,
  GASOLINE_EMISSIONS_IN_KG_PER_LITER
} from "../../../client_customizations/data/assumptions/ASSUMPTIONS"
import calcCharging from "../../../functions/vehicle/Charging/calcCharging";

const CarDetailsCalcs = ({ car }) => {
  const intl = useIntl()
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => setActiveTab(tab);

  const userPrefs = useContext(UserPrefsContext);
  const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");
  const electricMilesPortionForPhev = userPrefs.get(
    "electricMilesPortionForPhev"
  );

  const carIsPHEV = isPHEV(car);
  const equivalentGasVehicle = car.equivalent_gas_vehicle || {};
  const comparisonText = (
    <p className="text-left">
      <FormattedMessage
        id="ev.carDetails.compareText"
        defaultMessage="We selected the {gasVehicle} based on its proximity to the {vehicle} based on brand, size, and price."
        description="Compare Text"
        values={{
          gasVehicle: FormatCarName(equivalentGasVehicle),
          vehicle: FormatCarName(car)
        }}
      />
    </p>
  );

  return (
    <div className="CalcPanels">
      <Nav className="nav-fill">
        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "1" ? "active" : ""
            }`}
            onClick={() => toggle("1")}
          >
            <span>
              <FormattedMessage
                id="ev.carDetails.calc.charging"
                defaultMessage="Charging"
                description="Charging"
              />
            </span>
          </button>
        </li>

        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "2" ? "active" : ""
            }`}
            onClick={() => toggle("2")}
          >
            <span>
              <FormattedMessage
                id="ev.carDetails.calc.fuel"
                defaultMessage="Fuel"
                description="Fuel"
              />
            </span>
          </button>
        </li>

        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "3" ? "active" : ""
            }`}
            onClick={() => toggle("3")}
          >
            <span>
              <FormattedMessage
                id="ev.carDetails.calc.emissions"
                defaultMessage="Emissions"
                description="Emissions"
              />
            </span>
          </button>
        </li>
      </Nav>
      <TabContent activeTab={activeTab}>
        <br />
        <TabPane tabId="1">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage
                    id="ev.carDetails.calc.description"
                    defaultMessage="Description"
                    description="Description"
                  />
                </th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">
                  <FormattedMessage
                    id="ev.carDetails.calc.source"
                    defaultMessage="Source"
                    description="Source"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {!carIsPHEV && (
                <>
                  <tr>
                    <th scope="row">
                      <FormattedMessage
                        id="ev.carDetails.calc.DCFC"
                        defaultMessage="DC Fast Charging (DCFC) Rate"
                        description="DC Fast Charging (DCFC) Rate"
                      />
                    </th>
                    <td>{calcCharging.speedInKw("dc_fast_charger")} kW</td>
                    <td />
                  </tr>
                  <tr>
                    <th scope="row">
                    {process.env.REACT_APP_METRIC_SYSTEM ?
                      <FormattedMessage
                        id="ev.carDetails.calc.kwPerKm"
                        defaultMessage="kWh per 100 km"
                        description="kWh per 100 km"
                      />
                      :
                      <FormattedMessage
                        id="ev.carDetails.calc.kwPerMile"
                        defaultMessage="kWh per 100 mi"
                        description="kWh per 100 mi"
                      />
                    }
                    </th>
                    <td>{car.electric_efficiency} kWh</td>
                    <td>
                      {process.env.REACT_APP_METRIC_SYSTEM ? 
                      <a
                        href={"https://fcr-ccc.nrcan-rncan.gc.ca/en"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="ev.cardetails.calc.EPAMetric"
                          defaultMessage="NRC Efficiency Rating"
                          description="NRC Efficiency Rating"
                        />
                      </a>
                      :
                      <FormattedMessage
                        id="ev.cardetails.calc.EPA"
                        defaultMessage="EPA Efficiency Rating"
                        description="EPA Efficiency Rating"
                      />
                      }
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {process.env.REACT_APP_METRIC_SYSTEM ? 
                      <FormattedMessage
                        id="vehicle.kilometersPerThirtyFastCharge"
                        defaultMessage="Kilometeres per 30 min of fast charging"
                        description="Kilometeres per 30 min of fast charging"
                      />
                      :
                      <FormattedMessage
                        id="vehicle.milesPerThirtyFastCharge"
                        defaultMessage="Miles per 30 min of Fast Charging"
                        description="Miles per 30 min of Fast Charging"
                      />
                      }
                    </th>
                    <td>
                      ~{" "}
                      {FormatAsInt(
                        calcCharging.milesPerHalfHourOfCharge(
                          car.electric_efficiency,
                          "dc_fast_charger"
                        )
                      )}{" "}
                      {process.env.REACT_APP_METRIC_SYSTEM ?
                      <FormattedMessage
                        id="vehicle.kilometers"
                        defaultMessage="kilometers"
                        description="kilometers"
                      />
                      :
                      <FormattedMessage
                        id="vehicle.miles"
                        defaultMessage="miles"
                        description="miles"
                      />
                      }
                    </td>
                    <td>
                      <FormattedMessage
                        id="ev.carDetails.calc.chargingRate"
                        defaultMessage="Charging Rate"
                        description="Charging Rate"
                      /> / ({process.env.REACT_APP_METRIC_SYSTEM ?
                        <FormattedMessage
                          id="ev.carDetails.calc.kwPerKm"
                          defaultMessage="kWh per 100 km"
                          description="kWh per 100 km"
                        />
                        :
                        <FormattedMessage
                          id="ev.carDetails.calc.kwPerMile"
                          defaultMessage="kWh per 100 mi"
                          description="kWh per 100 mi"
                        />
                      } / 100) / 2</td>
                  </tr>
                </>
              )}
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="ev.cardetails.calc.vehicleBatteryCapacity"
                    defaultMessage="Vehicle Battery Capacity"
                    description="Vehicle Battery Capacity"
                  />
                </th>
                <td>{car.battery_capacity} kWh</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="ev.cardetails.calc.maxACIntake"
                    defaultMessage="Vehicle Max AC Intake"
                    description="Vehicle Max AC Intake"
                  />
                </th>
                <td>{car.ac_charging_power} kW</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="ev.cardetails.calc.lvlTwoChargingRate"
                    defaultMessage="Level 2 Charging Rate"
                    description="Level 2 Charging Rate"
                  />
                </th>
                <td>{calcCharging.speedInKw("level_2")} kW</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="ev.cardetails.calc.chargingRate"
                    defaultMessage="Charging Rate"
                    description="Charging Rate"
                  />
                </th>
                <td>
                  {Math.min(
                    calcCharging.speedInKw("level_2"),
                    car.ac_charging_power
                  )}{" "}
                  kW
                </td>
                <td>
                  <FormattedMessage
                    id="ev.cardetails.calc.minOflvl2AC"
                    defaultMessage="Minimum of Level 2 Charging Rate and Vehicle Max AC Intake"
                    description="Minimum of Level 2 Charging Rate and Vehicle Max AC Intake"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="ev.cardetails.calc.lvl2FullCharge"
                    defaultMessage="Time to Full Charge for Level 2"
                    description="Time to Full Charge for Level 2"
                  />
                </th>
                <td>
                  ~{" "}
                  {FormatAsTime(
                    calcCharging.timeForFullBattery(
                      car.battery_capacity,
                      car.ac_charging_power,
                      "level_2"
                    )
                  )}
                </td>
                <td>
                  <FormattedMessage
                    id="ev.cardetails.calc.vehicleBatteryCapacity"
                    defaultMessage="Vehicle Battery Capacity"
                    description="Vehicle Battery Capacity"
                  /> /
                  <FormattedMessage
                    id="ev.cardetails.calc.chargingRate"
                    defaultMessage="Charging Rate"
                    description="Charging Rate"
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </TabPane>
        <TabPane tabId="2">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage
                    id="ev.cardetails.calc.description"
                    defaultMessage="Description"
                    description="Description"
                  />
                </th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">{FormatCarName(equivalentGasVehicle)}</th>
                <th scope="col">
                  <FormattedMessage
                    id="ev.cardetails.calc.source"
                    defaultMessage="Source"
                    description="Source"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.kmDrivenPerYr"
                    defaultMessage="Kilometers Driven Per Year"
                    description="Kilometers Driven Per Year"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.milesDrivenPerYr"
                    defaultMessage="Miles driven per year"
                    description="Miles driven per year"
                  />
                  }
                </th>
                <td colSpan="2">{FormatAsThousands(milesDrivenAnnually)}</td>
                <td>
                  <FormattedMessage
                    id="ev.cardetails.calc.userInput"
                    defaultMessage="User Input"
                    description="User Input"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.cardetails.calc.lPerKm"
                    defaultMessage="Liters Per 100 Kilometers"
                    description="Liters Per 100 Kilometers"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.milesPerGallon"
                    defaultMessage="Miles Per Gallon (MPG)"
                    description="Miles Per Gallon (MPG)"
                  />
                  }
                </th>
                <td>
                  {carIsPHEV && (car.fossil_fuel_efficiency + process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "ev.cardetails.calc.lPer100Km", defaultMessage: "L/100 km"}) : intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"}))}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{equivalentGasVehicle.fossil_fuel_efficiency + (process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "ev.cardetails.calc.lPer100Km", defaultMessage: "L/100 km"}) : intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"}))}</td>
                <td />
              </tr>
              {carIsPHEV && (
                <tr>
                  <th scope="row">
                    <FormattedMessage
                      id="ev.cardetails.calc.portionElectric"
                      defaultMessage="Portion of Electric Driving"
                      description="Portion of Electric Driving"
                    />
                  </th>
                  <td>{FormatAsPercentRounded(electricMilesPortionForPhev)}</td>
                  <td>N/A</td>
                  <td>
                    <FormattedMessage
                      id="ev.cardetails.calc.portionElectricSource"
                      defaultMessage="User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline."
                      description="User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline."
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.litersUsed"
                    defaultMessage="Liters Used"
                    description="Liters Used"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.gallonsUsed"
                    defaultMessage="Gallons Used"
                    description="Gallons Used"
                  />
                  }
                </th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.fuelUsed(
                        car,
                        milesDrivenAnnually,
                        electricMilesPortionForPhev
                      )
                    )}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>
                  {FormatAsThousands(
                    calcEmissions.fuelUsed(
                      equivalentGasVehicle,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  )}
                </td>
                <td>
                {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.litersUsedFormula"
                    defaultMessage="Kilometers driven * (1 - Portion of Electric Driving) * L/100 km"
                    description="Kilometers driven * (1 - Portion of Electric Driving) * L/100 km"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.gallonsUsedFormula"
                    defaultMessage="Miles driven * (1 - Portion of Electric Driving) / MPG"
                    description="Miles driven * (1 - Portion of Electric Driving) / MPG"
                  />
                }
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.litersSaved"
                    defaultMessage="Liters Saved"
                    description="Liters Saved"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.gallonsSaved"
                    defaultMessage="Gallons Saved"
                    description="Gallons Saved"
                  />
                }
                </th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.fuelSaved(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  )}
                </td>
                <td>
                {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.litersSavedFormula"
                    defaultMessage="Liters used by Equivalent Gas Vehicle - Liters used by EV"
                    description="Liters used by Equivalent Gas Vehicle - Liters used by EV"
                  />
                :
                  <FormattedMessage
                    id="ev.cardetails.calc.gallonsSavedFormula"
                    defaultMessage="Gallons used by Equivalent Gas Vehicle - Gallons used by EV"
                    description="Gallons used by Equivalent Gas Vehicle - Gallons used by EV"
                  />
                }
                </td>
              </tr>
            </tfoot>
          </table>
          {comparisonText}
        </TabPane>
        <TabPane tabId="3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage
                    id="ev.carDetails.calc.description"
                    defaultMessage="Description"
                    description="Description"
                  />
                </th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">{FormatCarName(equivalentGasVehicle)}</th>
                <th scope="col">
                {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.carDetails.calc.kmDrivenPerYr"
                    defaultMessage="Kilometers Driven Per Year"
                    description="Kilometers Driven Per Year"
                  />
                  :
                  <FormattedMessage
                    id="ev.carDetails.calc.milesDriven"
                    defaultMessage="Miles Driven Per Year"
                    description="Miles Driven Per Year"
                  />
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  ELECTRICITY_EMISSIONS_IN_KG_PER_MWH.title :
                  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.title}
                </th>
                <td>{process.env.REACT_APP_METRIC_SYSTEM ?
                ELECTRICITY_EMISSIONS_IN_KG_PER_MWH.valueAsFormatted:
                ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.valueAsFormatted}</td>
                <td>N/A</td>
                <td>
                {process.env.REACT_APP_METRIC_SYSTEM ?
                <a
                    href={ELECTRICITY_EMISSIONS_IN_KG_PER_MWH.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="ev.cardetails.calc.nationalAverageElectricityEmissions"
                      defaultMessage="National Average Electricity Emissions"
                      description="National Average Electricity Emissions"
                    />
                  </a>:
                  null
                }
                </td> 
              </tr>
              <tr>
                <th scope="row">
                {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.carDetails.calc.kmDrivenPerYr"
                    defaultMessage="Kilometers Driven Per Year"
                    description="Kilometers Driven Per Year"
                  />
                  :
                  <FormattedMessage
                    id="ev.carDetails.calc.milesDriven"
                    defaultMessage="Miles Driven Per Year"
                    description="Miles Driven Per Year"
                  />
                  }
                </th>
                <td colSpan="2">{FormatAsThousands(milesDrivenAnnually)}</td>
                <td>
                  <FormattedMessage
                    id="ev.cardetails.calc.userInput"
                    defaultMessage="User Input"
                    description="User Input"
                  />
                </td>
              </tr>
              {carIsPHEV && (
                <tr>
                  <th scope="row">
                    <FormattedMessage
                    id="ev.cardetails.calc.portionElectric"
                    defaultMessage="Portion of Electric Driving"
                    description="Portion of Electric Driving"
                    />
                  </th>
                  <td>{FormatAsPercentRounded(electricMilesPortionForPhev)}</td>
                  <td>N/A</td>
                  <td>
                    <FormattedMessage
                      id="ev.cardetails.calc.portionElectricSource"
                      defaultMessage="User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline."
                      description="User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline."
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="ev.carDetails.calc.kwPerMile"
                    defaultMessage="kWh per 100 mi"
                    description="kWh per 100 mi"
                  />
                </th>
                <td>{car.electric_efficiency} kWh</td>
                <td>N/A</td>
                <td>
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <a
                  href={GASOLINE_EMISSIONS_IN_KG_PER_LITER.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="ev.cardetails.calc.FuelConsumptionCa"
                    defaultMessage="Canada Fuel Consumption Guide"
                    description="Canada Fuel Consumption Guide"
                  />
                  </a>
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.EPA"
                    defaultMessage="EPA Efficiency Rating"
                    description="EPA Efficiency Rating"
                  />
                  }
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.cardetails.calc.electricEmissionsMetric"
                    defaultMessage="Electric Emissions in kg"
                    description="Electric Emissions in kg"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.electricEmissions"
                    defaultMessage="Electric Emissions in Lbs"
                    description="Electric Emissions in Lbs"
                  />
                  }
                </th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.electricEmissions(
                        car.electric_efficiency,
                        milesDrivenAnnually *
                          (electricMilesPortionForPhev / 100)
                      )
                    )}
                  {!carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.electricEmissions(
                        car.electric_efficiency,
                        milesDrivenAnnually
                      )
                    )}
                </td>
                <td>N/A</td>
                <td>
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.electricEmissionsLbsMwhMetric"
                    defaultMessage="Electric Emissions in Kg/MWh"
                    description="Electric Emissions in Kg/MWh"
                  /> 
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.electricEmissionsLbsMwh"
                    defaultMessage="Electric Emissions in Lbs/MWh"
                    description="Electric Emissions in Lbs/MWh"
                  />
                  } *
                  {carIsPHEV && <span>
                    <FormattedMessage
                      id="ev.cardetails.calc.portionElectric"
                      defaultMessage="Portion of Electric Driving"
                      description="Portion of Electric Driving"
                    />*</span>}{" "}
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.kilometersDrivenAnnually"
                    defaultMessage="Kilometers Driven Per Year"
                    description="Kilometers Driven Per Year"
                  /> 
                  :
                  <FormattedMessage
                    id="ev.carDetails.calc.milesDriven"
                    defaultMessage="Miles Driven Per Year"
                    description="Miles Driven Per Year"
                  />
                  } * {" "}
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.carDetails.calc.kwPerKm"
                    defaultMessage="kWh per 100 km"
                    description="kWh per 100 km"
                  />
                  :
                  <FormattedMessage
                    id="ev.carDetails.calc.kwPerMile"
                    defaultMessage="kWh per 100 mi"
                    description="kWh per 100 mi"
                  />
                  }
                  {" "} / (100 * 1000)
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.carDetails.calc.electricityEmissionsMetric"
                    defaultMessage="Electricity Emissions in CO2 kg/MWh"
                    description="Electricity Emissions in CO2 kg/MWh"
                  /> 
                  :
                  <FormattedMessage
                    id="ev.carDetails.calc.ev.cardetails.calc.electricityEmissions"
                    defaultMessage="Electricity Emissions in CO2 lbs/MWh"
                    description="Electricity Emissions in CO2 lbs/MWh"
                  />
                  } 
                </th>
                <td>
                  {carIsPHEV ?
                  process.env.REACT_APP_METRIC_SYSTEM ?
                    GASOLINE_EMISSIONS_IN_KG_PER_LITER.valueAsFormatted :
                    GASOLINE_EMISSIONS_IN_LBS_PER_GAL.valueAsFormatted
                  :
                  "N/A"}
                </td>
                <td>{process.env.REACT_APP_METRIC_SYSTEM ?
                GASOLINE_EMISSIONS_IN_KG_PER_LITER.valueAsFormatted :GASOLINE_EMISSIONS_IN_LBS_PER_GAL.valueAsFormatted}</td>
                <td>
                {process.env.REACT_APP_METRIC_SYSTEM ?
                  <a
                    href={GASOLINE_EMISSIONS_IN_KG_PER_LITER.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  <FormattedMessage
                    id="ev.cardetails.calc.FuelConsumptionCa"
                    defaultMessage="Canada Fuel Consumption Guide"
                    description="Canada Fuel Consumption Guide"
                  />
                  </a>
                  :
                  null}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.cardetails.calc.lPerKm"
                    defaultMessage="Liters Per 100 Kilometers"
                    description="Liters Per 100 Kilometers"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.milesPerGallon"
                    defaultMessage="Miles Per Gallon (MPG)"
                    description="Miles Per Gallon (MPG)"
                  />
                  }
                </th>
                <td>
                  {carIsPHEV && (car.fossil_fuel_efficiency + process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "ev.cardetails.calc.lPer100Km", defaultMessage: "L/100 km"}) : intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"}))}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{equivalentGasVehicle.fossil_fuel_efficiency + (process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "ev.cardetails.calc.lPer100Km", defaultMessage: "L/100 km"}) : intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"}))}</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.cardetails.calc.gasolineEmissionsYrMetric"
                    defaultMessage="Gasoline Emissions in kg of CO2/yr"
                    description="Gasoline Emissions in kg of CO2/yr"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.gasolineEmissionsYr"
                    defaultMessage="Gasoline Emissions in lbs of CO2/yr"
                    description="Gasoline Emissions in lbs of CO2/yr"
                  />
                  }
                </th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.gasolineEmissions(
                        car.fossil_fuel_efficiency,
                        milesDrivenAnnually *
                          (1 - electricMilesPortionForPhev / 100)
                      )
                    )}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>
                  {FormatAsThousands(
                    calcEmissions.gasolineEmissions(
                      equivalentGasVehicle.fossil_fuel_efficiency,
                      milesDrivenAnnually
                    )
                  )}
                </td>
                <td>
                {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.cardetails.calc.gasolineEmissionsMetric"
                    defaultMessage="Gasoline Emissions in Kg per Liter"
                    description="Gasoline Emissions in Kg per Liter"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.gasolineEmissions"
                    defaultMessage="Gasoline Emissions in Lbs per Gallon"
                    description="Gasoline Emissions in Lbs per Gallon"
                  />
                  }
                  {" "}*{" "}
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.carDetails.calc.kmDrivenPerYr"
                    defaultMessage="Kilometers Driven Per Year"
                    description="Kilometers Driven Per Year"
                  />
                  :
                  <FormattedMessage
                    id="ev.carDetails.calc.milesDriven"
                    defaultMessage="Miles Driven Per Year"
                    description="Miles Driven Per Year"
                  />
                  }
                  {" "}
                  {carIsPHEV && (
                    <span>* (1 - <FormattedMessage
                      id="ev.cardetails.calc.portionElectric"
                      defaultMessage="Portion of Electric Driving"
                      description="Portion of Electric Driving"
                    />) </span>
                  )}
                  {process.env.REACT_APP_METRIC_SYSTEM ?
                  "*"
                  :
                  "/"
                  }
                  {" "}{process.env.REACT_APP_METRIC_SYSTEM ?
                  <FormattedMessage
                    id="ev.cardetails.calc.lPerKm"
                    defaultMessage="Liters Per 100 Kilometers"
                    description="Liters Per 100 Kilometers"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.milesPerGallon"
                    defaultMessage="Miles Per Gallon (MPG)"
                    description="Miles Per Gallon (MPG)"
                  />
                  }
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage
                  id="ev.cardetails.calc.emissionsReductions"
                  defaultMessage="Emissions Reductions"
                  description="Emissions Reductions"
                /></th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.emissionReduction(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  ) + (process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "ev.cardetails.calc.kgCo2PerYr", defaultMessage: " kgs of CO2 per year"}) : intl.formatMessage({ id: "ev.cardetails.calc.lbsCo2PerYr", defaultMessage: " lbs of CO2 per year"}))}
                </td>
                <td>
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.gasolineEmissionskg"
                    defaultMessage="Gasoline Emissions in Kgs"
                    description="Gasoline Emissions in Kgs"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.gasolineEmissionsLbs"
                    defaultMessage="Emissions Reductions"
                    description="Emissions Reductions"
                  />
                  }
                  {" "}-{" "} 
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                  <FormattedMessage
                    id="ev.cardetails.calc.electricEmissionsMetric"
                    defaultMessage="Electric Emissions in kg"
                    description="Electric Emissions in kg"
                  />
                  :
                  <FormattedMessage
                    id="ev.cardetails.calc.electricEmissions"
                    defaultMessage="Electric Emissions in Lbs"
                    description="Electric Emissions in Lbs"
                  />
                  }
                </td>
              </tr>
              <tr>
                <th scope="row">{process.env.REACT_APP_METRIC_SYSTEM ?
                EMISSIONS_PER_TREE_IN_KG_OF_CO2.title :
                EMISSIONS_PER_TREE_IN_LBS_OF_CO2.title}</th>
                <td colSpan="2">
                  {process.env.REACT_APP_METRIC_SYSTEM ?EMISSIONS_PER_TREE_IN_KG_OF_CO2.valueAsFormatted :
                  EMISSIONS_PER_TREE_IN_LBS_OF_CO2.valueAsFormatted}
                </td>
                <td>
                  <a
                    href={process.env.REACT_APP_METRIC_SYSTEM ? 
                      EMISSIONS_PER_TREE_IN_KG_OF_CO2.link :
                      EMISSIONS_PER_TREE_IN_LBS_OF_CO2.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="ev.cardetails.calc.arborDayFoundation"
                      defaultMessage="Arbor Day Foundation"
                      description="Arbor Day Foundation"
                    />
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="ev.carDetails.treesPlanted"
                    defaultMessage="Trees Planted"
                    description="Trees Planted"
                  />
                </th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.treesPlanted(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  ) + " trees"}
                </td>
                <td>
                  <FormattedMessage
                    id="ev.cardetails.calc.emissionsReductions"
                    defaultMessage="Emissions Reductions"
                    description="Emissions Reductions"
                  /> / <FormattedMessage
                    id="ev.cardetails.calc.emissionsPerTree"
                    defaultMessage="Emissions Saved per Tree"
                    description="Emissions Saved per Tree"
                  />
                </td>
              </tr>
            </tfoot>
          </table>
          {comparisonText}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CarDetailsCalcs;

CarDetailsCalcs.propTypes = {
  car: PropTypes.object
};
