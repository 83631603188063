import React from "react";
import "./Footer.scss";
import logo from "../../../assets/images/PRlogo.svg";
import logoEsp from "../../../assets/images/logo_esp.svg"
import facebook from "../../../assets/images/facebook.svg";
import linkedin from "../../../assets/images/linkedin.svg";
import twitter from "../../../assets/images/twitter.svg";
import { FormattedMessage } from "react-intl";
const Footer = ({ language, changeLanguage }) => {
  const isMobile = window.innerWidth < 426;

  return (
    <div className="Footer">
      <div className="container-fluid" style={{ backgroundColor: "#00819E" }}>
        <div className="container" style={{ padding: "10px" }}>
          <div style={{ marginBottom: 0, color: "#666666", fontSize: "14px" }}>
            <div className="footer-elements">
              <div>
                <span style={{ marginRight: "10px" }}>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://efficiencyworks.org`}
                    aria-label="Link to Efficiency Works"
                  >
                    {language === "EN" ? (
                      <img src={logo} alt=""></img>
                    ) : (
                      <img src={logoEsp} alt=""></img>
                    )}
                  </a>
                </span>
                <span className="subtext">
                  <FormattedMessage
                    id="footer.copyright"
                    defaultMessage="Copyright"
                    description="Copyright Footer"
                  />
                  &copy; 2022 -{" "}
                  {process.env.REACT_APP_FULL_COMPANY_NAME} |
                </span>{" "}
                <span className="subtext" style={{ marginLeft: "5px" }}>
                  {" "}
                  <a
                    className="white-text"
                    href={`https://www.prpa.org/privacy-policy/`}
                  >
                    <FormattedMessage
                      id="footer.privacy"
                      defaultMessage="Privacy Policy"
                      description="Privacy Policy Footer"
                    />
                  </a>
                  &nbsp; |
                </span>
                {isMobile && <div style={{ margin: "8px" }} />}
                <span className="disclaimer_container">
                  <FormattedMessage
                    id="footer.disclaimer"
                    defaultMessage="Figures shown on this page are estimates based on data provided by third-parties. This material is provided for informational purposes only and should not be relied on for tax, legal, or financial advice.  Neither ZappyRide® nor J.D. Power® provides tax, legal or financial advice. You should independently verify the estimates provided here before engaging in any transaction."
                    description="Footer Disclaimer"
                  />
                </span>
              </div>
              <div className="logos">
                <div className="box">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://www.facebook.com/EfficiencyWorksColorado`}
                    aria-label="Link to Facebook"
                  >
                    <img className="linked-images" src={facebook} alt=""></img>
                  </a>
                </div>
                <div className="box">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://twitter.com/EfficiencyWork`}
                    aria-label="Link to Twitter"
                  >
                    <img className="linked-images" src={twitter} alt=""></img>
                  </a>
                </div>
                <div className="box">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://www.linkedin.com/groups/6593186/profile`}
                    aria-label="Link to LinkedIn"
                  >
                    <img className="linked-images" src={linkedin} alt=""></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
