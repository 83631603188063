import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Seo from  "../../../components/Seo/Seo"


import PropTypes from "prop-types";

import "./PageLayout.scss";
import FavIcon from "../../assets/images/favicon.ico"
import defaultImage from "../../assets/images/metaImages/generic-preview.png"

import Header from "./Header/Header";
import Main from "./Main/Main";
import Footer from "./Footer/Footer";

const PageLayout = ({ children, page,description, props, image, language, changeLanguage, userLocation }) => {
  let path = props && props.match ? props.match.path : null

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path])

  return (
    <div className="PageLayout">
      <Helmet defer={true}>
        <link rel="shortcut icon" href={FavIcon} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <Seo
        image={image ? image : defaultImage}
        title={page}
        path={props ? props.location.pathname : ""}
        description={description}
        twitterHandle={
          process.env.REACT_APP_COMPONENTS_SEO_TWITTER_HANDLE
            ? process.env.REACT_APP_COMPONENTS_SEO_TWITTER_HANDLE
            : null
        }
      />

      <Header
        page={page}
        userLocation={userLocation}
        language={language}
        changeLanguage={changeLanguage}
      />
      <Main>
        <span id="main-content-id" tabIndex="-1" />
        {children}
      </Main>
      <Footer language={language} changeLanguage={changeLanguage} />
    </div>
  );
};

export default PageLayout;

PageLayout.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
  page: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string
};
