import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import { Link } from "react-router-dom";

import calcCharging from "../../functions/vehicle/Charging/calcCharging";
import calcEmissions from "../../functions/vehicle/Emissions/calcEmissions";
import { FormatAsInt, FormatAsThousands, FormatAsTime, FormatCarName, FormatAsDollars } from "../../utils/Helpers/Format";

import VehicleImage from "../../components/VehicleImage/VehicleImage"
import getPaymentDetails from "../../functions/vehicle/getPaymentDetails"

import "./ComparedCarDetails.scss";

import { useIntl } from 'react-intl';

const DetailColumn = ({ rowClassName, title, value, columns, first }) => (
  <div className={rowClassName}>
    <div className={`DetailColumn col-${columns || 6}`}>
      <p className="title">{title}</p>
      <p className={first ? `first h5` : `h5`}>{value}</p>
    </div>
  </div>
);

DetailColumn.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.number
};

DetailColumn.defaultProps = {
  columns: 12,
  value: "",
  rowClassName: "row"
};

const ComparedCarDetails = ({ vehicle }) => {

  const userPrefs = useContext(UserPrefsContext);
  const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");
  const intl = useIntl()

  let paymentDetails = {}

  if (vehicle) {
    paymentDetails = getPaymentDetails(
      vehicle,
      userPrefs
    )
  }

  let evImgs = vehicle.images ? vehicle.images.map( (n) =>
  (n.url_thumbnail == null) ?  [] : 
  (n.url_full == null) ?  []: 
  [n]) : [];

  //Flatten evImgs into a single array                  
  const evImgSrc = [].concat.apply([], evImgs);

  const mainVehicleImage = vehicle.images ? (vehicle.images.length >= 0 ? evImgSrc[0] : "") : "";

  const isGasTest = (fuelType, ifYes, ifNot) => {
    return (fuelType === "gas") ? ifYes : ifNot;
  }

  const fuelTypeNames = { 
    PHEV: intl.formatMessage({ id: "vehicle.phev", defaultMessage: "Electricity and Gasoline"}), 
    BEV: intl.formatMessage({ id: "vehicle.bev", defaultMessage: "Electricity"}),
  }

  const vehicleTypes = {
    Sedan: intl.formatMessage({ id: "vehicle.type.sedan", defaultMessage: "Sedan"}),
    Hatchback: intl.formatMessage({ id: "vehicle.type.hatchback", defaultMessage: "Hatchback"}),
    Coupe: intl.formatMessage({ id: "vehicle.type.coupe", defaultMessage: "Coupe"}),
    Crossover: intl.formatMessage({ id: "vehicle.type.crossover", defaultMessage: "Crossover"}),
    Minivan: intl.formatMessage({ id: "vehicle.type.minivan", defaultMessage: "Minivan"}),
    SUV: intl.formatMessage({ id: "vehicle.type.suv", defaultMessage: "SUV"}),
    Wagon: intl.formatMessage({ id: "vehicle.type.wagon", defaultMessage: "Wagon"}),
    Truck: intl.formatMessage({ id: "vehicle.type.truck", defaultMessage: "Truck"})
  }

  const renderVehicle = (fuelType) => {
    return (
      <>
        <DetailColumn
          rowClassName="row afterIncentives"
          title={intl.formatMessage({
            id: "vehicle.afterIncentives",
            defaultMessage: "After Incentives",
          })}
          value={isGasTest(
            fuelType,
            FormatAsDollars(vehicle.msrp),
            FormatAsDollars(paymentDetails.afterIncentives)
          )}
          first={true}
        />

        <DetailColumn
          rowClassName="row msrp"
          title={intl.formatMessage({
            id: "vehicle.msrp",
            defaultMessage: "MSRP",
          })}
          value={FormatAsDollars(vehicle.msrp)}
        />

        <DetailColumn
          rowClassName="row estimatedIncentives"
          title={intl.formatMessage({
            id: "vehicle.estimatedIncentives",
            defaultMessage: "ESTIMATED INCENTIVES",
          })}
          value={`${isGasTest(
            fuelType,
            "N/A",
            FormatAsDollars(paymentDetails.totalIncentivesForPurchase)
          )}`}
        />

        <DetailColumn
          rowClassName="row vehicleType"
          title={intl.formatMessage({
            id: "vehicle.type",
            defaultMessage: "TYPE",
          })}
          value={vehicleTypes[vehicle.form_factor]}
        />

        <DetailColumn
          rowClassName="row fuelType"
          title={intl.formatMessage({
            id: "vehicle.fuelType",
            defaultMessage: "FUEL TYPE",
          })}
          value={`${isGasTest(
            fuelType,
            intl.formatMessage({ id: "gasoline", defaultMessage: "Gasoline" }),
            fuelTypeNames[vehicle.fuel]
          )}`}
        />

        <DetailColumn
          rowClassName="row batterySize"
          title={intl.formatMessage({
            id: "vehicle.batterySize",
            defaultMessage: "BATTERY SIZE",
          })}
          value={isGasTest(fuelType, "N/A", `${vehicle.battery_capacity} kWh`)}
        />

        <DetailColumn
          rowClassName="row electricRange"
          title={intl.formatMessage({
            id: "vehicle.electricRange",
            defaultMessage: "ELECTRIC RANGE",
          })}
          value={isGasTest(
            fuelType,
            "N/A",
            `${vehicle.electric_range} ${
              process.env.REACT_APP_METRIC_SYSTEM
                ? intl.formatMessage({
                    id: "vehicle.kilometersShort",
                    defaultMessage: "km",
                  })
                : intl.formatMessage({
                    id: "vehicle.miles",
                    defaultMessage: "miles",
                  })
            }`
          )}
        />
        <DetailColumn
          rowClassName="row timeToChargeL2"
          title={intl.formatMessage({
            id: "vehicle.timeToChargeLvlTwo",
            defaultMessage: "TIME TO CHARGE - LEVEL 2",
          })}
          value={isGasTest(fuelType, "N/A", timeToChargeLevel2)}
        />
        <DetailColumn
          title={
            process.env.REACT_APP_METRIC_SYSTEM
              ? intl.formatMessage({
                  id: "vehicle.kilometersPerThirtyFastCharge",
                  defaultMessage: "KILOMETERS PER 30 MIN OF FAST CHARGING",
                })
              : intl.formatMessage({
                  id: "vehicle.milesPerThirtyFastCharge",
                  defaultMessage: "MILES PER 30 MIN OF FAST CHARGING",
                })
          }
          value={isGasTest(fuelType, "N/A", chargingSpeedText)}
        />
        <DetailColumn
          title={intl.formatMessage({
            id: "vehicle.coTwoEmissions",
            defaultMessage: "CO2 EMISSIONS REDUCTION",
          })}
          value={isGasTest(fuelType, "N/A", emissionsText)}
        />
        {fuelType === "gas" ? null : (
          <Link
            to={`/vehicles/${vehicle.handle}`}
            className="show-hide-btn-arrow btn"
            role="button"
          >
            {`${intl.formatMessage({
              id: "vehicle.view",
              defaultMessage: "View",
            })} ${FormatCarName(vehicle)}`}
          </Link>
        )}
      </>
    );
  }

  const electricMilesPortionForPhev = userPrefs.get(
    "electricMilesPortionForPhev"
  );

  const milesPerHalfHourOfCharge = FormatAsInt(
    calcCharging.milesPerHalfHourOfCharge(
      vehicle.electric_efficiency,
      "dc_fast_charger"
    )
  );
  const chargingSpeedText = `~ ${milesPerHalfHourOfCharge} ${process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometersShort", defaultMessage: "km" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}`;

  const co2ReductionInTons = FormatAsThousands(
    calcEmissions.emissionReduction(
      vehicle,
      milesDrivenAnnually,
      electricMilesPortionForPhev
    )
  );

  const treesPlanted = FormatAsThousands(
    calcEmissions.treesPlanted(
      vehicle,
      milesDrivenAnnually,
      electricMilesPortionForPhev
    )
  );

  const timeToChargeLevel2 = FormatAsTime(
    calcCharging.timeForFullBattery(
      vehicle.battery_capacity,
      vehicle.ac_charging_power,
      "level_2"
    )
  )

  const emissionsText = `${co2ReductionInTons} ${process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "kgperyear", defaultMessage: "kg/yr" }) : intl.formatMessage({id: "lbsperyear" , defaultMessage: "lbs/yr"})} = ${treesPlanted} ${intl.formatMessage({ id: "vehicle.trees", defaultMessage: "trees"})}`;


  return (
    <div className="ComparedCarDetails row">
      <div className="col-sm-12">
        <VehicleImage image={mainVehicleImage} size="full" alt={FormatCarName(vehicle)} />

        {renderVehicle(vehicle.fuel)}

      </div>
    </div>
  );
};

ComparedCarDetails.propTypes = {
  vehicle: PropTypes.object.isRequired
};

export default ComparedCarDetails;