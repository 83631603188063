import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import HomepageIncentives from "./components/HomepageIncentives";
import getLocalVehicles from "../../../functions/vehicle/getLocalVehicles";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehiclesCarousel from "../../../components/VehicleCarousel/VehicleCarousel"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

import bannerMain from "../../assets/images/banner.jpg";
import estesMain from "../../assets/images/EstesPark_REDUCED.jpg";
import lovelandMain from "../../assets/images/Loveland_REDUCED.jpg";
import longmontMain from "../../assets/images/Longmont_REDUCED.jpg";
import fortCollinsMain from "../../assets/images/FortCollins_REDUCED.jpg";

const Homepage = ({
  electricVehicles,
  homePageBannerImage,
  incentives,
  zipcode,
  userLocation,
  ip,
  uuid
}) => {
  
  const [banner, setBanner] = useState(bannerMain);
  const [vehicles, setVehicles] = useState([]);


  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  }

   const setBannerImage = (zipcode) => {
     const banners = {
       80517: estesMain,
       80522: fortCollinsMain,
       80501: longmontMain,
       80537: lovelandMain,
     };
     for (let zip in banners) {
       if (zip === zipcode) {
         setBanner(banners[zip]);
       }
     }
   };

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_TITLE;
    setElectricVehicles();
    setBannerImage(zipcode);
  });


  const renderDetails = electricVehicles && incentives ?
    (
      <>
        <VehiclesCarousel
          electricVehicles={getLocalVehicles(vehicles)}
        />
        <HomepageIncentives incentives={incentives} />
      </>
    ) : (
      <section className="container">
        <LoadingSpinner />
      </section>
    );

  return (
    <>
       <HomepageBigPromise homePageBannerImage={banner} />
      {renderDetails}
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
