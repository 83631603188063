import React, {useContext} from "react";
import PropTypes from "prop-types";


import SelectVehicleMakeFilter from "../../../components/InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter";
import SelectVehicleModelFilter from "../InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter"
import SelectVehicleTrimFilter from "../InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter";

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormattedMessage } from "react-intl";

const IncentiveVehicleOptions = ({ electricVehicles }) => {

  const userPrefs = useContext(UserPrefsContext);
  return (
    <div className="input-well MatchScoreOptions">
      <p className="h2">
        <FormattedMessage
          id="evs.incentivesVehicleOptions"
          defaultMessage="Refine Match Score"
          description="Refine Match Score"
        />
      </p>
      <SelectVehicleMakeFilter vehicles={electricVehicles} />
      <SelectVehicleModelFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get("vehicleMakeFilter")}
      />
      <SelectVehicleTrimFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get("vehicleMakeFilter")}
        modelOption={userPrefs.get("vehicleModelFilter")}
      />
    </div>
  );
};

export default IncentiveVehicleOptions;

IncentiveVehicleOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
