import React, {useState, useContext } from "react";
import {ValidateFiveDigitCode} from "../../../utils/Helpers/PostalCodeValidator"; 

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import IconZip from "../../../client_customizations/assets/images/icons/icon-zip.png";
import { FormattedMessage, useIntl } from 'react-intl';

const InputZipcodeForDealersMap = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  const [workingZipcode,setWorkingZipcode] = useState(
    userPrefs.get("zipcode")
  );

  const isInvalid = userPrefs.zipcodeIsNotFound && !userPrefs.zipcodeIsUpdating;

  const id = "input-zipcode-for-dealers-map";
  const idAria = id + " help";


  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== "Enter") return

    if(ValidateFiveDigitCode(workingZipcode) && !userPrefs.zipcodeIsUpdating){

      async function asyncCall() {
        await userPrefs.set({ workingZipcode: workingZipcode });
        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall()
    }
  }

  return (
    <>
      <div className="ZipcodeInputWithButton">
        <div className="form-group">
         <label>
          <FormattedMessage 
            id="zipcode"
            defaultMessage="Zipcode"
            description="Zipcode"
          />
         </label>
          <div className="input-group mb-2 mr-sm-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <img src={IconZip} alt="" style={{ width: 15 }} />
              </div>
            </div>
            <input
              id={id}
              className={isInvalid ? "zipcode form-control is-invalid" : "zipcode form-control"}
              aria-describedby={idAria}
              value={workingZipcode}
              onChange={e => setWorkingZipcode (e.target.value)}
              onKeyDown={e => handleUpdateButtonPress(e, "input")}
            />
          </div>
        </div>
        <div className="form-group text-center">
          <button
            type="button"
            className="btn-ae btn-default mb-2 btn-block"
            onClick={() => handleUpdateButtonPress()}
            disabled={userPrefs.zipcodeIsUpdating}
            id="update-zipcode-button"
          >
            {intl.formatMessage ? intl.formatMessage({ id: "chargingMap.searchDealers", defaultMessage: "Search Qualified Dealers"}) : "Search Qualified Dealers"}
          </button>
        </div>
      {(isInvalid || !ValidateFiveDigitCode( workingZipcode)) && (
        <div className="text-danger small">
          <FormattedMessage 
            id="chargingMap.errorZipcode"
            defaultMessage="Error: {workingZipcode} is not a valid zipcode"
            description="Zipcode Error"
            values= {{
              workingZipcode: workingZipcode
            }}
          />
        </div>
      )}
      </div>
    </>
  );
};

export default InputZipcodeForDealersMap;
