
import React, { useContext } from "react";

import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

const InputHeader = ({ userLocation }) => {

    const userPrefs = useContext(UserPrefsContext);
    
    const handleUpdateButtonPress = (input) => {

        if (!userPrefs.zipcodeIsUpdating) {
          let tax = userPrefs.get("selectSalesTaxElecRate");
          async function asyncCall() {
            await userPrefs.set({
              workingZipcode: input,
              salesTax: tax[input][0],
              electricityRate: tax[input][1],
            });
            if (!userPrefs.zipcodeIsUpdating) {
              userPrefs.syncWorkingZipcode();
            }
          }
          asyncCall();
        }
    };
        
    const platteLocations = () => {
        let communities = [];
        let platteSet = userPrefs.get("selectLocations");
        for (const key in platteSet) {
            communities.push(
              <React.Fragment key={key}>
                | &nbsp;
                <div
                  onClick={() => handleUpdateButtonPress(platteSet[key])}
                  className={
                    userLocation && userLocation.municipality === key
                      ? "headerButton blue"
                      : "headerButton"
                  }
                >
                  {key}
                  &nbsp;
                </div>
              </React.Fragment>
            );
        };
      return communities;
      }
      

    return (
      <>
        {platteLocations()}
      </>
    );
}

export default InputHeader;

InputHeader.propTypes = {
  userLocation: PropTypes.object,
};
