import React, { useContext } from "react";
import PropTypes from "prop-types";
import unique from "lodash/uniq";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import Select from "../../shared/InputElements/Select"
import { useIntl } from 'react-intl';

const SelectVehicleMakeFilter = ({
  id = "select-vehicle-make-filter",
  vehicles,
  ...rest
}) => {
  const intl = useIntl()

  const userPrefs = useContext(UserPrefsContext);
  const vehicleMakeOptions = unique((vehicles || []).map((ev) => ev.make));

  const label = intl.formatMessage ? intl.formatMessage({ id: "make", defaultMessage: "Make"}) : "Make"
  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleMakeFilter")}
      label={label}
      optionNames={[
        intl.formatMessage
          ? intl.formatMessage({
              id: "incentives.selectMake",
              defaultMessage: "Select Make",
            })
          : "Select Make",
        ...vehicleMakeOptions || [],
      ]}
      optionValues={["All", ...vehicleMakeOptions]}
      handler={(e) => {
        const vehicleHandles = vehicles.filter(
          (vehicle) => vehicle.make === e.target.value
        );
        vehicleHandles.length > 0
          ? userPrefs.set({
              vehicleMakeFilter: e.target.value,
              vehicleHandleForIncentives: vehicleHandles[0].handle,
            })
          : userPrefs.set({
              vehicleMakeFilter: e.target.value,
            });
      }}
    />
  );
};

export default SelectVehicleMakeFilter;

SelectVehicleMakeFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
